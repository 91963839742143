import * as React from 'react'
import PropTypes from 'prop-types'

import Layout from '@/layouts/Default'
import { Helmet } from 'react-helmet'

import NewsletterForm from '@/components/Forms/NewsletterForm'

import {
  Wrapper,
  Title
} from '@/styles/pages/newsletter/style'

const NewsletterPage = ({ pageContext: { pagePath, marketCode: pageMarketCode } }) => {
  return (
    <Layout path={ pagePath } pageType='Newsletter' plainHeader pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>Newsletter | Join Us | Cloudy Bay</title>
        <meta name='description' content='Let’s stay in touch. Sign up to our newsletter, to receive our news, tips, recipes and offers straight to your inbox.' />
      </Helmet>
      <Wrapper>
        <Title>
          Newsletter
        </Title>
        <NewsletterForm />
      </Wrapper>
    </Layout>
  )
}

NewsletterPage.propTypes = {
  pageContext: PropTypes.object.isRequired
}

export default NewsletterPage
