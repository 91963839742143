import styled from '@emotion/styled'
import { mediaMax, sectionTitle } from '../../mixins'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 5rem;
  gap: 5rem;
`

export const Title = styled.h1`
  ${sectionTitle()};
  max-width: 80rem;
  text-align: left;
  width: 100%;

  ${mediaMax.xs} {
    padding: 0 5vw;
  }
`
